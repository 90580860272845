import {gsap} from "gsap";

export default function(targetClass,ScrollTrigger, ScrollToPlugin,thisIndex){

    if (!document.querySelector(targetClass)) {
        return;
    }

        let currentSlide,
            carousel,
            carousel_status = false,
            cols = gsap.utils.toArray(targetClass+" .o-cols"),
            nav_li = document.querySelectorAll(targetClass+" .c-charts__nav li"),
            nav_li_active,
            status = false,
            panels = gsap.utils.toArray(targetClass+ " .c-charts__page"),
            scrollTween,
            article = gsap.utils.toArray(targetClass+ " .c-charts .o-cols .o-article");

        const navLinks = gsap.utils.toArray(targetClass+ " .c-charts__nav li a");

        navLinks.forEach(el => {
            el.addEventListener("click", e => {
                e.preventDefault();

                ScrollTrigger.refresh();
                let offset = -10,
                    num = thisIndex(e.currentTarget.parentNode);

                window.innerWidth > 1024 ?
                    currentSlide <= num ? offset = -30 : offset = 30 :
                    offset = 129;

                if(window.innerWidth > 1024)
                    offset = (window.innerHeight/2) - 400;

                nav_li_active = e.currentTarget.parentNode;
                console.log(nav_li_active);

                gsap.to(window, {duration: .5, scrollTo: {y: e.currentTarget.getAttribute('href'), offsetY: offset} });
            });
        });

        const goToSection = (i) => {
            currentSlide = i;
        }

        const navTrigger = (i) => {
            if (window.innerWidth > 1024) {
                for (let k = 0; k < nav_li.length; k++) {
                    nav_li[k].classList.remove('is-active');
                }
                nav_li[i].classList.add('is-active');
            } else {
                for (let k = 0; k < nav_li.length; k++) {
                    nav_li[k].classList.remove('is-active');
                }
                nav_li[i].classList.add('is-active');

            }
        }

        const initCharts = function() {

            ScrollTrigger.matchMedia({
                "(min-width: 1025px)": function() {

                    gsap.from(document.querySelector(targetClass), {
                        id: 'nav',
                        scrollTrigger: {
                            trigger: targetClass,
                            pin: targetClass+ ' .c-charts__nav',
                            start: "top top",
                            end: "bottom bottom",
                            onEnter: ({ progress, direction, isActive }) => {
                                gsap.to(targetClass+ ' .c-charts__nav', {
                                    autoAlpha: 1,
                                    x: 0,
                                    stagger: 0.25,
                                });
                            }
                        }
                    });
                },

                "(max-width: 1024px)": function() {

                    gsap.from(document.querySelector('.c-charts'), {
                        scrollTrigger: {
                            trigger: targetClass,
                            pin: targetClass+ ' .c-charts__nav',
                            pinSpacing: false,
                            start: "top 70px",
                            end: "bottom bottom",
                            onEnter: () => {
                                document.querySelector('.c-topbar').classList.add('no-border');
                            },
                            onLeave: () => {
                                document.querySelector('.c-topbar').classList.remove('no-border');
                            },
                            onLeaveBack: ({ progress, direction, isActive }) => {
                                document.querySelector('.c-topbar').classList.remove('no-border');
                            },

                            onEnterBack: ({ progress, direction, isActive }) => {
                                document.querySelector('.c-topbar').classList.remove('no-border');
                            }
                        }
                    });
                }
            })

            cols.forEach((item, i) => {

                ScrollTrigger.matchMedia({
                    "(min-width: 1025px)": function() {
                        gsap.from(item, {
                            ease: "none",
                            scrollTrigger: {
                                trigger: item,
                                start: "top 50%",
                                end: "bottom bottom",
                                onToggle: self => self.isActive ? console.log('') : goToSection(i),
                                onEnter: ({ progress, direction, isActive }) => {
                                    navTrigger(i);
                                },

                                onLeave: ({ progress, direction, isActive }) => {
                                    navTrigger(i);
                                },

                                onLeaveBack: ({ progress, direction, isActive }) => {
                                    navTrigger(i);
                                },

                                onEnterBack: ({ progress, direction, isActive }) => {
                                    navTrigger(i);
                                }
                            }
                        });
                    },
                    "(max-width: 1024px)": function() {
                        gsap.from(item, {
                            ease: "none",
                            scrollTrigger: {
                                trigger: item,
                                start: "top 140px",
                                end: "bottom bottom",
                                onToggle: self => self.isActive ? console.log('') : goToSection(i),
                                onEnter: ({ progress, direction, isActive }) => {
                                    navTrigger(i);
                                },

                                onLeave: ({ progress, direction, isActive }) => {
                                    navTrigger(i);
                                },

                                onLeaveBack: ({ progress, direction, isActive }) => {
                                    navTrigger(i);
                                },

                                onEnterBack: ({ progress, direction, isActive }) => {
                                    navTrigger(i);
                                }
                            }
                        });
                    }
                });

                ScrollTrigger.matchMedia({
                    "(min-width: 1025px)": function() {
                        gsap.from(item.querySelector('.o-article'), {
                            id: 'art'+i,
                            ease: "none",
                            scrollTrigger: {
                                trigger: item,
                                scrub: 1,
                                start: 'top 5%',
                                end: 'top 5%',
                                onEnter: ({ progress, direction, isActive }) => {
                                    currentSlide = i;
                                    navTrigger(i);
                                },
                            },
                            opacity: 0,
                            scale: 1.2
                        });
                    }
                });
            })
        }

        initCharts();


}
