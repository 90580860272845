import { gsap } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

document.addEventListener('DOMContentLoaded', () => {
    
    gsap.registerPlugin(ScrollToPlugin);

    const cover = document.getElementById('cover');
    
    const init = () => {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        
        setTimeout(() => {
            window.anims();
            window.carousels();
            cover.remove();               
        }, 250);   
        
        if (window.location.hash) {
            let target = window.location.hash,
                offset = 0;
            
            if (document.documentElement.classList.contains('mobile')) {
               target = target+'-mobile';
               offset = 140;
            }    

            gsap.to(window, {
                duration: .5, 
                scrollTo: {
                    y: document.querySelector(target),
                    offsetY: offset
                }
            });                
        }         
    };

    window.addEventListener('load', init);

}, false);
